/* eslint-disable react-native/no-inline-styles */
import React, {useEffect, useMemo, useRef, useState} from 'react';
import {View, ScrollView} from 'react-native';
import DetailNavTitle from '@/components/business/detail-nav-title';
import {copyText, goBack, goTo, useResponsiveDimensions} from '@/utils';
import {useFocusEffect} from '@react-navigation/native';
import Modal from './modal';
import {NativeTouchableOpacity} from '@basicComponents/touchable-opacity';
import globalStore from '@/services/global.state';
import {getVipRender} from '@businessComponents/vip';
import {VipRenderType} from '@businessComponents/vip';

import {
  uploadProfilePhotoBase64,
  updateProfile,
  getDefaultAvatar,
} from './pofile.service';
import {postUserInfo} from '@/services/global.service';
import i18n from '@i18n';
import Upload from './upload';
import BoxShadow from '@/components/basic/shadow';

import {
  flex,
  margin,
  font,
  padding,
  background,
  position,
  borderRadius,
  overflow,
  fontColor,
  basicColor,
} from '@/components/style';
import LazyImage from '@/components/basic/image';
import Text from '@basicComponents/text';
import {LazyImageBackground} from '@basicComponents/image';

import {
  pofileImgWidth,
  defaultHeaderImg,
  pofileMagin,
  RectangleImgWidth,
  pofilePadding,
  edit,
  rightIcon,
  pic,
  padding20,
  borderRadius50,
  profileImg,
} from './profile.variable';

import {ToastType, useToast} from '@/components/basic/modal';
import {SmartPhone, IDIcon, LockIcon, AddImg, CopyImg} from './svg.variable';
import Spin from '@/components/basic/spin';
// import {homePage} from '@/config';

const borderBox = {
  borderColor: basicColor.primary,
};
const PersonalCenter = () => {
  const [avatar, setAvatar] = useState<any>([]);
  const {renderModal, show} = useToast();
  const [info, setInfo] = useState<any>(globalStore.userInfo);

  // This effect will run every time the screen comes into focus
  useFocusEffect(
    React.useCallback(() => {
      getUserInfo();
    }, []),
  );

  useEffect(() => {
    getDefaultAvatar().then(res => setAvatar(res));
  }, []);

  const getUserInfo = async () => {
    const userInfo: any = await postUserInfo();
    globalStore.userInfo = userInfo;
    setInfo(userInfo);
  };

  const currentVipOption: VipRenderType = useMemo(
    () => getVipRender(info?.level || 0),
    [info?.level],
  );

  const editRef: any = useRef(null);

  const imagePicker: any =
    globalStore.isAndroid && require('react-native-image-picker');

  // 从相册中选择
  const changeAvatar = async () => {
    imagePicker.launchImageLibrary(
      {
        mediaType: 'photo',
        maxWidth: 1000, // 设置选择照片的大小，设置小的话会相应的进行压缩
        maxHeight: 1000,
        quality: 0.8,
        includeBase64: true,
      },
      async (res: any) => {
        if (res.didCancel) {
          return false;
        }
        if (globalStore.isAndroid) {
          // 对获取的图片进行处理
          const ret: any = await uploadProfilePhotoBase64({
            data: 'data:image/png;base64,' + res?.assets[0]?.base64,
          });
          await updateProfile(info?.userName, ret);
          getUserInfo();
        }
      },
    );
  };
  const handleChangeAvatar = async (img: any) => {
    await updateProfile(info?.userName, img);
    getUserInfo();
  };

  const handleCopy = () => {
    if (info?.userId) {
      copyText('' + (info?.userId + 100000));
      show({
        type: ToastType.success,
        message: i18n.t('share.copy-success'),
      });
    }
  };

  // 去绑定
  const goBind = () => {
    // 跳转去绑定页面，定义bind 页面路由
    // goTo('BindPhone', {
    //   source: 'profile',
    // });
    window.location.href =
      window.location.origin + '/bind-phone?source=profile';
    // console.log('去绑定');
  };

  const {width: screenWidth, height: screenHeight} = useResponsiveDimensions();
  const [loading, setLoading] = useState(false);
  // console.log(globalStore.userInfo, 'globalStore.userInfo********');
  return (
    <LazyImageBackground
      occupancy={'transparent'}
      style={[background.lightGrey]}
      imageUrl={profileImg}
      width={screenWidth}
      height={(screenWidth / 375) * 372}>
      <DetailNavTitle
        title={'My Profile'}
        hideServer={true}
        hideAmount={true}
        onBack={() => goBack()}
      />
      <Spin
        loading={loading}
        style={[
          {
            height: screenHeight,
          },
        ]}>
        <View
          style={[
            flex.row,
            margin.btmm,
            flex.center,
            flex.centerByCol,
            pofileMagin,
            position.rel,
            borderRadius50,
            overflow.hidden,
          ]}>
          <LazyImage
            occupancy={'transparent'}
            resizeMode="cover"
            imageUrl={info?.userAvatar ? info?.userAvatar : defaultHeaderImg}
            width={pofileImgWidth}
            height={pofileImgWidth}
            radius={pofileImgWidth}
          />
          <View style={[position.abs]}>
            {globalStore.isAndroid ? (
              <NativeTouchableOpacity onPress={() => changeAvatar()}>
                <LazyImage
                  occupancy={'transparent'}
                  imageUrl={pic}
                  width={32}
                  height={32}
                />
              </NativeTouchableOpacity>
            ) : (
              <Upload
                pic={
                  <LazyImage
                    occupancy={'transparent'}
                    imageUrl={pic}
                    width={48}
                    height={48}
                  />
                }
                getUserInfo={getUserInfo}
                startUpload={() => setLoading(true)}
                endUpload={() => setLoading(false)}
              />
            )}
          </View>
        </View>

        <View
          style={[
            pofilePadding,
            flex.flex,
            flex.centerByCol,
            flex.row,
            flex.center,
          ]}>
          <ScrollView
            showsVerticalScrollIndicator={false}
            horizontal={true}
            style={{width: 248}}>
            {avatar.map((item: any, index: number) => {
              return (
                <View
                  key={index}
                  style={
                    info?.userAvatar === item
                      ? [
                          margin.rights,
                          borderBox,
                          {borderRadius: 50, borderWidth: 2},
                        ]
                      : [
                          margin.rights,
                          {borderWidth: 2, borderColor: 'transparent'},
                        ]
                  }>
                  <NativeTouchableOpacity
                    onPress={() => handleChangeAvatar(item)}>
                    <LazyImage
                      occupancy={'transparent'}
                      imageUrl={item}
                      width={RectangleImgWidth}
                      height={RectangleImgWidth}
                      radius={50}
                    />
                  </NativeTouchableOpacity>
                </View>
              );
            })}
          </ScrollView>
          {globalStore.isAndroid ? (
            <NativeTouchableOpacity onPress={() => changeAvatar()}>
              <View
                style={[
                  margin.leftl,
                  {
                    width: 48,
                    height: 48,
                    backgroundColor: '#fff',
                    borderRadius: 50,
                  },
                  flex.center,
                ]}>
                <AddImg height={16} radius={50} fill={basicColor.primary} />
              </View>
            </NativeTouchableOpacity>
          ) : (
            <View style={[margin.leftl]}>
              <BoxShadow
                shadowStyle={{
                  radius: 50,
                  out: {x: 0, y: 4, blur: 4, color: 'rgba(0, 0, 0, 0.10)'},
                }}>
                <View
                  style={[
                    {
                      width: 48,
                      height: 48,
                      backgroundColor: '#fff',
                      borderRadius: 50,
                    },
                    flex.center,
                  ]}>
                  <Upload
                    pic={
                      <AddImg
                        height={16}
                        radius={50}
                        fill={basicColor.primary}
                      />
                    }
                    getUserInfo={getUserInfo}
                    startUpload={() => setLoading(true)}
                    endUpload={() => setLoading(false)}
                  />
                </View>
              </BoxShadow>
            </View>
          )}
        </View>
        <View style={[flex.flex, flex.center, flex.row, pofileMagin]}>
          {currentVipOption && currentVipOption.smallFn(20)}
          <Text
            style={[
              margin.leftxxs,
              margin.rights,
              font.second,
              font.fontInterBold,
              font.fm,
            ]}>
            {info?.userName}
          </Text>
          <NativeTouchableOpacity
            onPress={() => {
              editRef.current.show();
              editRef.current.handleSetName(info?.userName);
            }}>
            <LazyImage
              occupancy={'transparent'}
              imageUrl={edit}
              width={24}
              height={24}
            />
          </NativeTouchableOpacity>
        </View>
        <View style={[{width: '100%', padding: 12}]}>
          <View
            style={[
              padding.tbl,
              borderRadius.l,
              background.white,
              {width: '100%'},
            ]}>
            <View
              style={[
                padding20,
                flex.flex,
                flex.row,
                flex.between,
                flex.centerByCol,
                {height: 48},
              ]}>
              <View style={[flex.flex, flex.centerByCol, flex.row]}>
                <SmartPhone width={16} height={16} />
                <Text style={[margin.l, font.second, font.fm, font.accent]}>
                  Phone number
                </Text>
              </View>
              {/* 点击去绑定，跳转到绑定页面 */}
              {/* 新增绑定页面 */}

              {!info?.userPhone && (
                <div
                  onClick={goBind}
                  className="flex justify-center items-center gap-1 px-2 py-1.5 rounded border border-[var(--1)] bg-white">
                  <span className="text-[var(--1)] text-[0.875rem] font-bold capitalize leading-normal">
                    {i18n.t('me.user.bindPhone')}
                  </span>
                </div>
              )}
              {info?.userPhone && (
                <Text color={fontColor.second} style={[font.second, font.fm]}>
                  {info?.userPhone}
                </Text>
              )}
            </View>
            <View
              style={[
                padding20,
                flex.flex,
                flex.row,
                flex.between,
                flex.centerByCol,
                {height: 48},
              ]}>
              <View style={[flex.flex, flex.centerByCol, flex.row]}>
                <LockIcon width={16} height={16} />
                <Text style={[margin.l, font.second, font.fm, font.accent]}>
                  Change password
                </Text>
              </View>
              <NativeTouchableOpacity onPress={() => goTo('SetPassword')}>
                <View
                  style={[flex.flex, flex.centerByCol, flex.row, flex.center]}>
                  <Text
                    color={fontColor.second}
                    style={[margin.leftxxs, margin.l, font.second, font.fm]}>
                    ******
                  </Text>
                  <LazyImage
                    occupancy={'transparent'}
                    imageUrl={rightIcon}
                    width={14}
                    height={14}
                  />
                </View>
              </NativeTouchableOpacity>
            </View>
            <View
              style={[
                padding20,
                flex.flex,
                flex.row,
                flex.between,
                flex.centerByCol,
                {height: 48},
              ]}>
              <View style={[flex.flex, flex.centerByCol, flex.row]}>
                <IDIcon width={16} height={16} />
                <Text style={[margin.l, font.second, font.fm, font.accent]}>
                  User ID
                </Text>
              </View>
              <View
                style={[flex.flex, flex.centerByCol, flex.row, flex.center]}>
                <Text
                  color={fontColor.second}
                  style={[margin.leftxxs, margin.l, font.second, font.fm]}>
                  {info?.userId || '--'}
                  {/* {+(info?.userId || 0) + 100000} */}
                </Text>
                <NativeTouchableOpacity onPress={handleCopy}>
                  <CopyImg width={16} height={16} />
                </NativeTouchableOpacity>
              </View>
            </View>
          </View>
        </View>
      </Spin>
      <Modal ref={editRef} getUserInfo={getUserInfo} />
      {renderModal}
    </LazyImageBackground>
  );
};

export default PersonalCenter;
