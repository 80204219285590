// 绑定手机号页面
import React from 'react';
import DetailNavTitle from '@/components/business/detail-nav-title';
import i18n from '@i18n';
// import {sendCode} from '@/common-pages/login/login.service';
import {goBack, goTo} from '@/utils';
import PhoneInput from '../login/components/phone-input';
import CodeInput from '../login/components/code-input';
import {defaultPhoneCode, homePage} from '@/config';
import {Button} from 'antd-mobile';
import {bingPhone} from './pofile.service';
import {getToken} from '../login/login.service';
import globalStore from '@/services/global.state';
import {postUserInfo} from '@/services/global.service';

const BindPhone = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const source = urlParams.get('source');

  const [userPhone, setUserPhone] = React.useState('');
  const [userPhoneCode, setUserPhoneCode] = React.useState(defaultPhoneCode);
  const [OTPCode, setOTPCode] = React.useState('');
  const setValueOrCode = (value: string) => {
    setOTPCode(value);
  };

  const handleLoginSuccess = () => {
    // Get stored callback information
    const callbackInfoStr = localStorage.getItem('loginCallbackInfo');
    if (!callbackInfoStr) {
      window.location.href = window.location.origin;
      return;
    }

    try {
      // const callbackInfo = JSON.parse(callbackInfoStr);
      // const {fromPage, sucessPage, sucessPageParams} = callbackInfo;

      // Clear stored data
      // 这里是否需要清除？？？？
      localStorage.removeItem('thirdPartySource');
      localStorage.removeItem('thirdPartyState');
      localStorage.removeItem('loginCallbackInfo');
      goTo(homePage, {screen: 'Home'});
      // if (sucessPage) {
      //   replace(sucessPage, sucessPageParams);
      // } else {
      //   if (fromPage === 'chat') {
      //     // toChat();
      //     // console.log('>>>>', 'Chat');
      //     // goTo('Chat');
      //     goTo(homePage, {screen: 'Chat'});
      //   } else if (
      //     !navigationRef.current ||
      //     navigationRef.current.getState().routes.length < 2
      //   ) {
      //     goTo(homePage, {screen: 'Home'});
      //   } else {
      //     goBack();
      //   }
      // }

      // Navigate based on stored callback info
      // if (sucessPage) {
      //   window.location.href =
      //     window.location.origin +
      //     '/' +
      //     sucessPage +
      //     (sucessPageParams
      //       ? '?' + new URLSearchParams(sucessPageParams).toString()
      //       : '');
      // } else if (fromPage === 'chat') {
      //   window.location.href = window.location.origin + '/chat';
      // } else if (backPage) {
      //   window.location.href = window.location.origin + '/' + backPage;
      // } else {
      //   window.location.href = window.location.origin;
      // }
    } catch (error) {
      console.error('Navigation error:', error);
      window.location.href = window.location.origin;
    }
  };

  // CONFIRM按钮点击绑定
  const handleBind = async () => {
    if (source === 'login') {
      // Get stored third-party login params
      const res = await getToken('', userPhone, OTPCode);
      // console.log(res);
      if (res?.token) {
        globalStore.token = res.token;
        globalStore.globalTotal.next({
          type: 'success',
          message: i18n.t('me.user.bindSuccess'),
          tip: i18n.t('me.user.bindSuccessContent'),
        });
        // 登录进来绑定手机号，返回首页
        setTimeout(() => {
          handleLoginSuccess();
        }, 100);
        return;
      }
      return;
    }
    // 从个人中心进入绑定手机号
    // Normal profile binding flow
    const res = await bingPhone({
      userPhone,
      code: OTPCode,
    });
    console.log(res);
    // 成功之后获取用户信息，然后回到原页面
    const userInfo = await postUserInfo();
    globalStore.userInfo = userInfo;
    globalStore.globalTotal.next({
      type: 'success',
      message: i18n.t('me.user.bindSuccess'),
      // tip: i18n.t('me.user.bindSuccessContent'),
    });
    if (source === 'profile') {
      // 从个人中心进入绑定手机号
      goTo('Profile');
      return;
    } else {
      // 从首页进入绑定手机号
      goBack();
      return;
    }
  };

  const handleSkip = async () => {
    // console.log('source', source, source === 'profile');
    try {
      if (source === 'login') {
        // 登录进来绑定手机号，返回首页
        const res = await getToken();
        if (res?.token) {
          globalStore.token = res.token;
          globalStore.globalTotal.next({
            type: 'success',
            message: i18n.t('me.user.bindSuccess'),
            tip: i18n.t('me.user.bindSuccessContent'),
          });
          handleLoginSuccess();
          // const userInfo = await postUserInfo();
          // globalStore.userInfo = userInfo;
          // handleLoginSuccess();
        }
        return;
      } else if (source === 'profile') {
        // console.log('从个人中心进入绑定手机号');
        // 从个人中心进入绑定手机号
        // goTo('Profile');
        goBack();
      } else {
        // 或者直接从首页进入绑定手机号
        goBack();
      }
    } catch (err) {
      console.log(err);
      goTo(homePage, {screen: 'Home'});
    }
  };

  return (
    <div className="w-full min-h-screen flex flex-col bg-white">
      <DetailNavTitle
        title={'Bind Phone'}
        hideServer={true}
        hideAmount={true}
        onBack={handleSkip}
      />
      <div className="flex flex-col gap-5 p-6">
        <div className="flex justify-end">
          <div
            className={
              'flex justify-center items-center gap-2.5 px-4 py-2 rounded-3xl border border-[var(--T4,#BBBBC5)] text-[var(--T3,#848490)] font-inter text-xs font-bold cursor-pointer'
            }
            onClick={handleSkip}>
            {i18n.t('me.user.skip')}
          </div>
        </div>
        <PhoneInput
          userPhone={userPhone}
          setUserPhone={setUserPhone}
          userPhoneCode={userPhoneCode}
          setUserPhoneCode={setUserPhoneCode}
        />
        <CodeInput
          setValueOrCode={setValueOrCode}
          switchIndex={1}
          userPhone={userPhone}
          userPhoneCode={userPhoneCode}
          OTPCode={OTPCode}
          userPassword={''}
        />
        <div>
          <Button
            disabled={!userPhone || !OTPCode}
            className={
              'w-full h-12 px-5 flex justify-center items-center gap-2.5 rounded-[28px] shadow-[0px_1px_4px_0px_rgba(0,0,0,0.25),0px_1px_0px_0px_rgba(255,255,255,0.20)_inset] text-white font-inter text-base font-bold uppercase bg-[var(--1)]'
            }
            onClick={handleBind}>
            {i18n.t('me.user.confirm')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default BindPhone;
